import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConstantsProvider} from 'src/app/modules/auth/_services/constants-provider.service';
import {map} from 'rxjs/operators';

@Injectable()
export class NewsService {
    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private constantsService: ConstantsProvider
    ) {
    }
    
    public API_NEWS_URL = this.constantsService.url+ 'api/news';
    public API_NEWS_COMMENT_URL = this.constantsService.url+ 'api/newsComments';
    public API_NEWS_CATEGORY_URL = this.constantsService.url+ 'api/NewsCategories';


    // getCategories(pagenumber?:number, pageSize?:number): Observable<any> {
    //     if(pagenumber==undefined){
    //         pagenumber = 0;
    //         pageSize=0;
    //     }
    //     return this._httpClient.get(this.API_NEWS_URL + `/paged?pageNumber=${pagenumber}&pageSize=${pageSize}`, this.constantsService.getAuthHeaderOption());
            
    // }
    getNews(): Observable<any> {
        return this._httpClient.get<any>(this.API_NEWS_URL, this.constantsService.getAuthHeaderOption());      
    }
    getNewsType(): Observable<any> {
        return this._httpClient.get<any>(this.API_NEWS_URL + '/news-types', this.constantsService.getAuthHeaderOption());      
    }
    
    getSingleNews(newsId): Observable<any> {
        return this._httpClient.get(this.API_NEWS_URL + '/' + newsId, this.constantsService.getAuthHeaderOption());
    }

    

    create(data): Observable<any> {
        delete data.id;
        return this._httpClient.post(this.API_NEWS_URL , data, this.constantsService.getAuthHeaderOption());
    }

    update(data): Observable<any> {
        return this._httpClient.put(this.API_NEWS_URL + '/' + data.id, data, this.constantsService.getAuthHeaderOption());
    }
    deleteProgram(programId): Observable<any> {
        return this._httpClient.delete(this.API_NEWS_URL + '/' + programId, this.constantsService.getAuthHeaderOption());
    }


    //News Comments
    getComments(): Observable<any> {
      return this._httpClient.get<any>(this.API_NEWS_COMMENT_URL, this.constantsService.getAuthHeaderOption());      
    }
  

    createComment(data): Observable<any> {
        delete data.id;
        return this._httpClient.post(this.API_NEWS_COMMENT_URL , data, this.constantsService.getAuthHeaderOption());
    }

    deletecomment(programId): Observable<any> {
        return this._httpClient.delete(this.API_NEWS_COMMENT_URL + '/' + programId, this.constantsService.getAuthHeaderOption());
    }
    updateComment(data): Observable<any> {
        return this._httpClient.put(this.API_NEWS_COMMENT_URL + '/' + data.id, data, this.constantsService.getAuthHeaderOption());
    }


  
    //News Categories
    getNewsCategories(): Observable<any> {
        return this._httpClient.get<any>(this.API_NEWS_CATEGORY_URL, this.constantsService.getAuthHeaderOption());      
    }
    
    getSingleNewsCategory(newsId): Observable<any> {
        return this._httpClient.get(this.API_NEWS_CATEGORY_URL + '/' + newsId, this.constantsService.getAuthHeaderOption());
    }

    deleteNewsCategory(programId): Observable<any> {
        return this._httpClient.delete(this.API_NEWS_CATEGORY_URL + '/' + programId, this.constantsService.getAuthHeaderOption());
    }
    createNewsCategory(data): Observable<any> {
        delete data.id;
        return this._httpClient.post(this.API_NEWS_CATEGORY_URL , data, this.constantsService.getAuthHeaderOption());
    }

    updateNewsCategory(data): Observable<any> {
        return this._httpClient.put(this.API_NEWS_CATEGORY_URL + '/' + data.id, data, this.constantsService.getAuthHeaderOption());
    }
}
