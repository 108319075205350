import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConstantsProvider} from 'src/app/modules/auth/_services/constants-provider.service';
import {map} from 'rxjs/operators';

@Injectable()
export class ProgramsService {
    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private constantsService: ConstantsProvider
    ) {
    }
    
    public API_PROGRAM_URL = this.constantsService.url+ 'api/programs';
   


    // getCategories(pagenumber?:number, pageSize?:number): Observable<any> {
    //     if(pagenumber==undefined){
    //         pagenumber = 0;
    //         pageSize=0;
    //     }
    //     return this._httpClient.get(this.API_PROGRAM_URL + `/paged?pageNumber=${pagenumber}&pageSize=${pageSize}`, this.constantsService.getAuthHeaderOption());
            
    // }
    getPrograms(): Observable<any> {
        return this._httpClient.get<any>(this.API_PROGRAM_URL, this.constantsService.getAuthHeaderOption());      
    }
    getAllLinkType(): Observable<any> {
        return this._httpClient.get<any>(this.API_PROGRAM_URL + '/linkTypes', this.constantsService.getAuthHeaderOption());      
    }
   
    getSingleProgram(programId): Observable<any> {
        return this._httpClient.get(this.API_PROGRAM_URL + '/' + programId, this.constantsService.getAuthHeaderOption());
    }
    
    deleteProgram(programId): Observable<any> {
        return this._httpClient.delete(this.API_PROGRAM_URL + '/' + programId, this.constantsService.getAuthHeaderOption());
    }

    
    create(data): Observable<any> {
        delete data.id;
        return this._httpClient.post(this.API_PROGRAM_URL , data, this.constantsService.getAuthHeaderOption());
    }

    update(data): Observable<any> {
        return this._httpClient.put(this.API_PROGRAM_URL + '/' + data.id, data, this.constantsService.getAuthHeaderOption());
    }

}
