import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsProvider } from '../../auth/_services/constants-provider.service';
import { observable, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

constructor(
  private _httpClient: HttpClient,
  private router: Router,
  private constantsService: ConstantsProvider
) { }

  changePassword(data): Observable<any> {
    return this._httpClient.put(this.constantsService.url + 'users/me/mypassword', data, this.constantsService.getAuthHeaderOption());
  }

}
