import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConstantsProvider} from 'src/app/modules/auth/_services/constants-provider.service';
import {map} from 'rxjs/operators';


@Injectable()
export class SubscriptionService {
    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private constantsService: ConstantsProvider
    ) {
    }
    
    public API_CATEGORY_URL = this.constantsService.url+ 'api/subscriptionPlans';


    // getCategories(pagenumber?:number, pageSize?:number): Observable<any> {
    //     if(pagenumber==undefined){
    //         pagenumber = 0;
    //         pageSize=0;
    //     }
    //     return this._httpClient.get(this.API_CATEGORY_URL + `/paged?pageNumber=${pagenumber}&pageSize=${pageSize}`, this.constantsService.getAuthHeaderOption());
            
    // }
    getSubscriptions(): Observable<any> {
        return this._httpClient.get(this.API_CATEGORY_URL, this.constantsService.getAuthHeaderOption());      
    }
    
    getSingleSubscription(subscriptionId): Observable<any> {
        return this._httpClient.get(this.API_CATEGORY_URL + '/' + subscriptionId, this.constantsService.getAuthHeaderOption());
    }

    getParentId():Observable<any>{
        return this._httpClient.get(this.API_CATEGORY_URL + '/parents', this.constantsService.getAuthHeaderOption());      

    }

    create(data): Observable<any> {
        delete data.id;
        return this._httpClient.post(this.API_CATEGORY_URL , data, this.constantsService.getAuthHeaderOption());
    }

    update(data): Observable<any> {
        return this._httpClient.put(this.API_CATEGORY_URL + '/' + data.id, data, this.constantsService.getAuthHeaderOption());
    }


      deleteSubscription(SubscriptionId): Observable<any> {
          return this._httpClient.delete(this.API_CATEGORY_URL + '/' + SubscriptionId, this.constantsService.getAuthHeaderOption());
    }
}
