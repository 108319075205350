import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConstantsProvider } from 'src/app/modules/auth/_services/constants-provider.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

constructor(
  private _httpClient: HttpClient,
  private router: Router,
  private constantsService: ConstantsProvider
) { }

  public API_SUBSCRIBER_URL = this.constantsService.url + 'api/Subscriptions';

  getTransactions(pagenumber?: number, pageSize?: number): Observable<any> {
    return this._httpClient.get(this.API_SUBSCRIBER_URL, this.constantsService.getAuthHeaderOption());
  }
}
