import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConstantsProvider} from 'src/app/modules/auth/_services/constants-provider.service';
import {map} from 'rxjs/operators';

@Injectable()
export class AdvertService {
    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private constantsService: ConstantsProvider
    ) {
    }
    
    public API_ADVERT_URL = this.constantsService.url+ 'api/adverts';
    public APIs_ADVERT_URL = this.constantsService.url + 'api/adverts/advert-sections';
   


    // getCategories(pagenumber?:number, pageSize?:number): Observable<any> {
    //     if(pagenumber==undefined){
    //         pagenumber = 0;
    //         pageSize=0;
    //     }
    //     return this._httpClient.get(this.API_ADVERT_URL + `/paged?pageNumber=${pagenumber}&pageSize=${pageSize}`, this.constantsService.getAuthHeaderOption());
            
    // }
    getAdverts(): Observable<any> {
        return this._httpClient.get<any>(this.API_ADVERT_URL, this.constantsService.getAuthHeaderOption());      
    }

    getAdvertSection(): Observable<any> {
        return this._httpClient.get<any>(this.APIs_ADVERT_URL, this.constantsService.getAuthHeaderOption());
    }
    
   
    getSingleAdvert(advertId): Observable<any> {
        return this._httpClient.get(this.API_ADVERT_URL + '/' + advertId, this.constantsService.getAuthHeaderOption());
    }

    

    deleteProgram(programId): Observable<any> {
        return this._httpClient.delete(this.API_ADVERT_URL + '/' + programId, this.constantsService.getAuthHeaderOption());
    }
    
    create(data): Observable<any> {
        delete data.id;
        return this._httpClient.post(this.API_ADVERT_URL , data, this.constantsService.getAuthHeaderOption());
    }

    update(data): Observable<any> {
        return this._httpClient.put(this.API_ADVERT_URL + '/' + data.id, data, this.constantsService.getAuthHeaderOption());
    }

}
