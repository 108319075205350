import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/_services/auth.service';
import{AuthenticationService} from './modules/auth/_services/auth-http/authentication.service'
import { environment } from 'src/environments/environment';
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
// #fake-end#

import { BlockUI, BlockUIModule } from 'ng-block-ui';
import { ChannelService } from './modules/e-commerce/_services/channels.service';
import { ConstantsProvider } from './modules/auth/_services/constants-provider.service';
import { NewsService } from './modules/e-commerce/_services/news.service';
import { SubscriptionService } from './modules/e-commerce/_services/subscriptions.service';
import { AnnouncementService } from './modules/e-commerce/_services/announcement.service';
import { AuthorsService } from './modules/e-commerce/_services/authors.service';
import { ProgramsService } from './modules/e-commerce/_services/programs.service';
import { AdvertService } from './modules/e-commerce/_services/advert.service';
import { UsersproviderService } from './modules/e-commerce/_services/usersprovider.service';
import { RolesProviderService } from './modules/e-commerce/_services/roles-provider.service';
import { TransactionServiceService } from './modules/e-commerce/_services/transaction-service.service';
import { SubscriberService } from './modules/e-commerce/_services/subscriber.service';
import { SubscriptionCodeService } from './modules/e-commerce/_services/subscriptionCode.service';
import { GenerateCodeService } from './modules/e-commerce/_services/generateCode.service';
import { ChangePasswordService } from './modules/e-commerce/_services/change-password.service';
import { QuillModule } from 'ngx-quill'
function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}



@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    QuillModule.forRoot(),
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    BlockUIModule.forRoot(),
    NgbModule,

  ],
  providers: [
    AdvertService,
    ProgramsService,
    AuthorsService,
    AnnouncementService,
    SubscriptionService,
    NewsService,
    ChannelService,
    ConstantsProvider,
    AuthenticationService,
    UsersproviderService,
    RolesProviderService,
    TransactionServiceService,
    SubscriberService,
  SubscriptionCodeService,
  GenerateCodeService,
  ChangePasswordService,
    
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
