import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConstantsProvider} from 'src/app/modules/auth/_services/constants-provider.service';
import {map} from 'rxjs/operators';

@Injectable()
export class ChannelService {
    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private constantsService: ConstantsProvider
    ) {
    }
    
    public API_CHANNEL_URL = this.constantsService.url+ 'api/Channels';


    // getCategories(pagenumber?:number, pageSize?:number): Observable<any> {
    //     if(pagenumber==undefined){
    //         pagenumber = 0;
    //         pageSize=0;
    //     }
    //     return this._httpClient.get(this.API_CHANNEL_URL + `/paged?pageNumber=${pagenumber}&pageSize=${pageSize}`, this.constantsService.getAuthHeaderOption());
            
    // }
    getChannels(): Observable<any> {
        return this._httpClient.get(this.API_CHANNEL_URL, this.constantsService.getAuthHeaderOption());      
    }
    getChannelParent(): Observable<any> {
        return this._httpClient.get(this.API_CHANNEL_URL +'/parent', this.constantsService.getAuthHeaderOption());      
    }
    
    getSingleChannel(channelId): Observable<any> {
        return this._httpClient.get(this.API_CHANNEL_URL + '/' + channelId, this.constantsService.getAuthHeaderOption());
    }


    create(data): Observable<any> {
        delete data.id;
        return this._httpClient.post(this.API_CHANNEL_URL , data, this.constantsService.getAuthHeaderOption());
    }

      deleteChannel(channelId): Observable<any> {
          return this._httpClient.delete(this.API_CHANNEL_URL + '/' + channelId, this.constantsService.getAuthHeaderOption());
    }

    update(data): Observable<any> {
        return this._httpClient.put(this.API_CHANNEL_URL + '/' + data.id, data, this.constantsService.getAuthHeaderOption());
    }
}
