import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConstantsProvider } from 'src/app/modules/auth/_services/constants-provider.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransactionServiceService {

constructor(
  private _httpClient: HttpClient,
  private router: Router,
  private constantsService: ConstantsProvider
) { }
  public API_TRANSACTION_URL = this.constantsService.url + 'api/Transactions';

  getTransactions(): Observable<any> {
    return this._httpClient.get(this.API_TRANSACTION_URL, this.constantsService.getAuthHeaderOption());
  }

  // getSingleSubscription(subscriptionId): Observable<any> {
  //   return this._httpClient.get(this.API_CATEGORY_URL + '/' + subscriptionId, this.constantsService.getAuthHeaderOption());
  // }

  // getParentId(): Observable<any> {
  //   return this._httpClient.get(this.API_CATEGORY_URL + '/parents', this.constantsService.getAuthHeaderOption());

  // }

  // create(data): Observable<any> {
  //   delete data.id;
  //   return this._httpClient.post(this.API_CATEGORY_URL, data, this.constantsService.getAuthHeaderOption());
  // }

  // update(data): Observable<any> {
  //   return this._httpClient.put(this.API_CATEGORY_URL + '/' + data.id, data, this.constantsService.getAuthHeaderOption());
  // }


}
