import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsProvider } from '../../auth/_services/constants-provider.service';
import { observable, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class UsersproviderService {

  constructor(
    private _httpClient: HttpClient,
    private router: Router,
    private constantsService: ConstantsProvider
  ) 
  {}

  /**
      * Get single-user
      *
      * @returns {Promise<any>}
      */
  getUsers(schoolId?, pagenumber?: number, pageSize?: number): Observable<any> {
    return this._httpClient.get(this.constantsService.url + 'users', this.constantsService.getAuthHeaderOption())
    // .pipe(
    //     map((result: any) => {
    //         result.map(user => {
    //             if (user.profilePics) {
    //                 user.profilePics = this.constantsService.url + user.profilePics;
    //             }
    //         });

    //         return result;
    //     })
    // );
  }

  getUser(userId): Observable<any> {
    return this._httpClient.get(this.constantsService.url + 'users/' + userId, this.constantsService.getAuthHeaderOption());
  }

  create(data): Observable<any> {
    delete data.id;
    return this._httpClient.post(this.constantsService.url + 'users', data, this.constantsService.getAuthHeaderOption());
  }
  deleteProgram(userId): Observable<any> {
    return this._httpClient.delete(this.constantsService.url + 'users/' + userId, this.constantsService.getAuthHeaderOption());
  }

  update(data): Observable<any> {
    return this._httpClient.put(this.constantsService.url + 'users/' + data.id, data, this.constantsService.getAuthHeaderOption());
  }
  

  updateme(data): Observable<any> {
    return this._httpClient.put(this.constantsService.url + 'users/me', data, this.constantsService.getAuthHeaderOption());
  }
  

  getUserInfo(): Observable<any> {
    return this._httpClient.get(this.constantsService.url + 'users/me',this.constantsService.getAuthHeaderOption());
  }

 
}
