import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Permission } from '../_models/permission.model'
import { ConstantsProvider } from '../../auth/_services/constants-provider.service';

@Injectable({
  providedIn: 'root'
})
export class RolesProviderService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private constantsService: ConstantsProvider
  ) { }


  public API_ROLES_URL = this.constantsService.url + 'roles';
  public API_PERMISSION_URL = this.constantsService.url + 'permissions';

  permissionList = [];
  getRoles(pagenumber?: number, pageSize?: number): Observable<any> {
    // if (pagenumber == undefined) {
    //   pagenumber = 0;
    //   pageSize = 0;

    // }
    return this.http.get(this.API_ROLES_URL, this.constantsService.getAuthHeaderOption());

  }


  getRole(itemId): Observable<any> {
    return this.http.get(this.API_ROLES_URL + '/' + itemId, this.constantsService.getAuthHeaderOption());
  }
  create(data): Observable<any> {
    delete data.id;
    return this.http.post(this.API_ROLES_URL, data, this.constantsService.getAuthHeaderOption());
  }

  update(data): Observable<any> {
    return this.http.put(this.API_ROLES_URL + '/' + data.id, data, this.constantsService.getAuthHeaderOption());
  }
  deleteProgram(programId): Observable<any> {
    return this.http.delete(this.API_ROLES_URL + '/' + programId, this.constantsService.getAuthHeaderOption());
  }

  // loadPermissions() {
  //   this.http.get(this.API_PERMISSION_URL, this.constantsService.getAuthHeaderOption())
  //   .subscribe(()
  //     data => {

  //     }
  //     },
  //   )
  // }
  loadPermissions(): Observable<any> {
    return this.http.get(this.API_PERMISSION_URL, this.constantsService.getAuthHeaderOption())



  }
  getpermish() {
    this.loadPermissions().subscribe(data => {
      this.permissionList = data;
    })
  }


}


