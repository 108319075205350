import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConstantsProvider } from 'src/app/modules/auth/_services/constants-provider.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GenerateCodeService {

constructor(
  private _httpClient: HttpClient,
  private router: Router,
  private constantsService: ConstantsProvider
) { }
  public API_CATEGORY_URL = this.constantsService.url + 'api/SubscriptionCodes/generate';
  public APIs_CATEGORY_URL = this.constantsService.url + 'api/SubscriptionCodes/subscribe';


  create(data): Observable<any> {
    delete data.id;
    return this._httpClient.post(this.API_CATEGORY_URL, data, this.constantsService.getAuthHeaderOption());
  }

  creates(data): Observable<any> {
    delete data.id;
    return this._httpClient.post(this.APIs_CATEGORY_URL, data, this.constantsService.getAuthHeaderOption());
  }
}
