import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
//import Swal, {SweetAlertResult} from 'sweetalert2';
import {HttpHeaders} from '@angular/common/http';
// import {NotifierService} from 'angular-notifier';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MatDialog } from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';


const helper = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})

export class ConstantsProvider {

    constructor(
        private router: Router,
        private _httpClient: HttpClient,
                // private _snackBar: MatSnackBar,
                // private _matDialog: MatDialog
    ) {
    }

   

    public tokenName = 'imedia-access-token';
    public claimName = 'imedia-id-token';
    public url = environment.baseUrl;

    public setReturnUrl(url: string): void {
        localStorage.setItem('nextReturnUrl', url);
    }

    public getReturnUrl(clearOld): string {
        const nextReturnUrl = localStorage.getItem('nextReturnUrl');
        if (clearOld) {
            localStorage.removeItem('nextReturnUrl');
        }

        let url = '';

        if (nextReturnUrl && nextReturnUrl !== 'null' && nextReturnUrl !== 'undefined' && nextReturnUrl !== '/') {
            url = nextReturnUrl;
        }
        return url;
    }

    public setToken(token): void {
        localStorage.setItem(this.tokenName, token);
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        // this.token = null;
        let nextUrl = null;
        if (localStorage.getItem('nextReturnUrl')) {
            nextUrl = localStorage.getItem('nextReturnUrl');
        }

        localStorage.clear();

        if (nextUrl) {
            this.setReturnUrl(nextUrl);
        }
        this.router.navigate(['/auth/login']);
    }

    public setClaim(claim): void {
        localStorage.setItem(this.claimName, claim);
    }

    public isUserAuthenticated(): boolean {
        const claim = this.getClaim();
        try {
            return !helper.isTokenExpired(claim);
        } catch (exception) {
        }
        return false;
    }

    public getToken(): string {
        return localStorage.getItem(this.tokenName);
    }

    public getClaim(): string {
        return localStorage.getItem(this.claimName);
    }

    public getAuthenticatedUser(): any {
        const claim = localStorage.getItem(this.claimName);
        if (claim) {
            try {
                const decoded = helper.decodeToken(claim);
                // console.log(decoded);
                return decoded;
            } catch (e) {
            }
            
        }

        return {
            email: '',
            permission: [],
            phone: '',
            role: '',
            fullname: ''
        };
    }

    uploadFile(fileName: string, file): Observable<any> {
        const formData: FormData = new FormData();
        formData.append(fileName, file);
        return this._httpClient.post(
            this.url + 'Upload', formData,
            this.getUploadHeader() 
        )
      }

    private extractError(err): string {
        console.log(err.status);
        if (!err) {
            return 'Unknown error';
        }

        if (typeof err === 'string') {
            return err;
        }

        if (!err.status && !err.ok) {
            return 'Unable to connect to server, are you connected?';
        }

        if (err.error && typeof err.error === 'object') {
            if (err.error.error_description) {
                return err.error.error_description;
            }


        }

        if (err.error && typeof err.error === 'string') {
            return err.error;
        }

        if (err.status === 401) {
            this.setReturnUrl(location.href);
           // this.router.navigate(['/auth/login']);
           this.logout()
        }

        if (err.message) {
            return err.message;
        }

        if(err.status === 201){
            return 'success';
        }

        if (err.status === 204) {
            return 'success';
        }


        return err.toString();

    }

    public handleError(err: any, showError = false): string {
        const data = this.extractError(err);
        // if (showError) {
        //     this.error(data);
        // }

        // console.log(data);

        return data;
    }

    getAuthHeaderOption(contentType = 'application/json'): any {
        const headers = new HttpHeaders({
            'Content-Type': contentType,
            'authorization': 'Bearer ' + this.getToken(),
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    getUploadHeader(): any {
        return new HttpHeaders({
            authorization: 'Bearer ' + this.getToken(),
        });
    }

    getRawDataHeaderOption(): any {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': 'Bearer ' + this.getToken(),
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    getOrdinaryHeaderOption(): any {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    getOrdinaryHeaderUrlEncodedOption(): any {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }
    succeeded(message: any): void {
        // this._snackBar.open(message, 'OK', {
        //     verticalPosition: 'top',
        //     duration: 3000,
        //     panelClass: ['green', 'darken-3']
        // });
        // this.notifier.notify('success', message);
    }

    error(message: any): void {
        // this._snackBar.open(message, 'CLOSE', {
        //     verticalPosition: 'top',
        //     duration: 3000,
        //     panelClass: ['red', 'darken-3']
        // });
        // this.notifier.notify('error', message);
    }

    // succeeded(message: any): void {
    //     this._snackBar.open(message, 'OK', {
    //         verticalPosition: 'top',
    //         duration: 3000,
    //         panelClass: ['green', 'darken-3']
    //     });
    //     // this.notifier.notify('success', message);
    // }

    // error(message: any): void {
    //     this._snackBar.open(message, 'CLOSE', {
    //         verticalPosition: 'top',
    //         duration: 3000,
    //         panelClass: ['red', 'darken-3']
    //     });
    //     // this.notifier.notify('error', message);
    // }


}
