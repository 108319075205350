import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from './auth-http/authentication.service';
import { AuthService } from './auth.service';
import { ConstantsProvider } from './constants-provider.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
    private authServices : ConstantsProvider,
    private userservices : AuthenticationService,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const currentUser = this.authServices.isUserAuthenticated;
    if (!this.authServices.isUserAuthenticated()) {

      // this.authServices.setReturnUrl(location.href);
      // logged in so return true
      this.authServices.error('Is either your session is expired or you have not login yet, please login first');
      this.router.navigate(['/auth/login']);
      return false;
    }

    // not logged in so redirect to login page with the return url
    // this.userservices.logout();
    return true;
  }
}
